export async function sha256Hmac(key: string, message: string) {
    const keyBytes = new TextEncoder().encode(key)
    const messageBytes = new TextEncoder().encode(message)
    const hmacKey = await crypto.subtle.importKey(
        'raw',
        keyBytes,
        { name: 'HMAC', hash: 'SHA-256' },
        false,
        ['sign']
    )

    const signature = await crypto.subtle.sign('HMAC', hmacKey, messageBytes)
    const signatureBytes = new Uint8Array(signature)

    return byteArrayToHexString(signatureBytes)
}

function byteArrayToHexString(byteArray: Uint8Array) {
    return Array.from(byteArray, byte => ('0' + (byte & 0xFF).toString(16)).slice(-2)).join('')
}
