import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
    breakpoints: {
        values: {
            mobile: 540,
            tablet: 768,
            laptop: 1025,
            desktop: 1280
        }
    }
})

export default theme
