import payReducer from './payReducer'
import loaderReducer from './loaderReducer'
import wsReducer from './wsReducer'
import initLoaderReducer from './initLoaderReducer'

export default {
    pay: payReducer,
    loader: loaderReducer,
    ws: wsReducer,
    initLoader: initLoaderReducer
}
