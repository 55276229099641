import { createReducer } from '@reduxjs/toolkit'

import loaderAction from '../action/loaderAction'

type IInitialState = {
    isLoading: boolean
}

const initialState: IInitialState = {
    isLoading: false
}

const loaderReducer = createReducer(initialState, builder => {
    builder
        .addCase(loaderAction.startLoading, (state) => {
            state.isLoading = true
        })
        .addCase(loaderAction.finishLoading, (state) => {
            state.isLoading = false
        })
})

export default loaderReducer
