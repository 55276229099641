import { createReducer } from '@reduxjs/toolkit'

import initLoaderAction from '../action/initLoaderAction'


type IInitialState = {
    isLoading: boolean
}

const initialState: IInitialState = {
    isLoading: true
}

const initLoaderReducer = createReducer(initialState, builder => {
    builder
        .addCase(initLoaderAction.start, (state) => {
            state.isLoading = true
        })
        .addCase(initLoaderAction.finish, (state) => {
            state.isLoading = false
        })
})

export default initLoaderReducer
