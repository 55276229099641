import api from '../api'

import { IPayRoutes, IPayService } from './pay.interface'
import SUBMIT_TYPE from './pay.constants'

const routes: IPayRoutes = {
    getPayInfo(id) {
        return `/payments/${id}`
    },
    validateCardDateExpire(id) {
        return `/payments/${id}/get_bin_info`
    },
    submitCardForm(id) {
        return `/payments/${id}/submit`
    },
    submitVoid(id) {
        return `/payments/${id}/submit_void`
    },
    submitVoidNoBody(id) {
        return `/payments/${id}/confirm_void_no_body`
    },
    submitUserAction(id) {
        return `/payments/${id}/user_action`
    }
}

const PayService: IPayService = {
    getPayInfo: (id) => api.get(routes.getPayInfo(id)),
    validateCardDateExpire: (id, body) => api.post(routes.validateCardDateExpire(id), body, { [SUBMIT_TYPE.CARD_DATE]: SUBMIT_TYPE.CARD_DATE }),
    submitCardForm: (id, body) => api.post(routes.submitCardForm(id), body),
    submitVoid: (id, body) => api.post(routes.submitVoid(id), body),
    submitVoidNoBody: (id) => api.post(routes.submitVoidNoBody(id)),
    submitUserAction: (id, body) => api.post(routes.submitUserAction(id), body)
}

export default PayService
