import calculateShaSignature from './calculateShaSignature'

export interface ISignatureMessage {
    method?: string
    url?: string
    sessionId: string
    body: string
}

export interface ICreateSignatureMessage {
    method?: string
    url?: string
    serviceId: string
    requestId: string
    body: string
}

export interface ISignatureParams {
    key: string
    message: ISignatureMessage | ICreateSignatureMessage
}

async function createSignature(params: ISignatureParams) {
    const {
        key,
        message
    } = params

    return await calculateShaSignature(key, Object.values(message).join('\n'))
}

export default createSignature
