import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { MuiThemeProvider } from '@material-ui/core'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { store } from './store'
import theme from './assets/theme'
import '@fontsource/roboto'
import './index.css'

import './i18n'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

root.render(
    <MuiThemeProvider theme={theme}>
        <BrowserRouter>
            <Provider store={store}>
                <Suspense>
                    <App />
                </Suspense>
            </Provider>
        </BrowserRouter>
    </MuiThemeProvider>
)

reportWebVitals()
