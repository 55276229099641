import { createReducer, PayloadAction } from '@reduxjs/toolkit'

import payAction from '../action/payAction'
import { WorkflowEnum } from '../../enums/workflow.enum'
import { TransactionStateEnum } from '../../enums/transactionState.enum'
import { IPayInfoData } from '../../interfaces/PayInfoResponse'
import { IValidateCardResponse } from '../../services/pay/pay.interface'

interface IInitialState {
    is_4symbol_cvc: boolean
    customer_info: {
        Country?: string
        Language?: string
        Town?: string
        Address?: string
        ZIP?: string
        Phone?: string
        Email?: string
        DateOfBirth?: string
        FirstName?: string
        LastName?: string
        zipCode?: string
        BankName?: string
        RFC?: string
        [key: string]: string | undefined
    } | null
    saved_customer_info: {
        Email?: string
        Phone?: string
        Address?: string
        Town?: string
        ZIP?: string
        FirstName?: string
        LastName?: string
        DateOfBirth?: string
        TypeDocument?: string
        AccountNumber?: string
        AccountTypeCode?: string
        BankName?: string
        PersonalId?: string
        RFC?: string
        [key: string]: string | undefined
    } | null
    interface: {
        work_flow?: WorkflowEnum
        flag_redirect_error_request?: boolean
        flag_redirect_success_request?: boolean
        url_error?: string
        url_success?: string
        ExtraData?: {
            DeclineURL?: string
            ReturnURL?: string
        }
        language?: string
        select_language?: string | null
    } | null
    main_data: {
        public_key: string
        service_id: string
        meta_data: {
            PaymentType: string
        }
    } | null
    receipt_data: Record<string, string> | null
    request: {
        OrderId: string
        Amount: string
        Currency: string
        Description: string
        RebillFlag: boolean
        ExtraData: {
            ReturnURL: string
            PersonalId: string
            AddressNumber?: string
            AddressStreet?: string
            RFC?: string
            State?: string
            ReturnUrl: string
            SuccessUrl: string
            DeclineUrl: string
            WebhookUrl: string
            personalId: string
            original_service_id: number
            AccountTypeCode?: string
        }
    } | null
    state: { name: TransactionStateEnum } | null
    ui_config: {
        validator: {
            [key: string]: {
                alternative_name: {
                    ru: string
                    en: string
                    az: string
                    es: string
                    id: string
                    ja: string
                    ms: string
                    pt: string
                    th: string
                    tr: string
                    vi: string
                    zh: string
                    ko: string
                }
                rules: Array<{
                    DependentFieldValue: string
                    pattern: string
                    error_message: {
                        ru: string
                        en: string
                        az: string
                        es: string
                        id: string
                        ja: string
                        ms: string
                        pt: string
                        th: string
                        tr: string
                        vi: string
                        zh: string
                        ko: string
                    }
                }>
            }
        }
        | null
        placeholder: { [key: string]: string } | undefined
        bankList: Array<{
            data: Array<Record<string, string>>
            [key: string]: string | undefined | Array<Record<string, string>>
        }> | null
        documents: Record<string, string> | null
        states: {
            [key: string]: Record<string, string>
        } | null
        countries: Array<string> | null
        BankName: {
            [key: string]: Record<string, string>
        } | null
        disableSocketIo: boolean
        integrationName: string
        cardholder_visible: boolean
        cardholder_mandatory: boolean
        geBaseConfig: {
            is_cvc_hidden: boolean
            is_revert_block_direction: boolean
        } | null
        customConfig: {
            is_address: boolean
            is_country: boolean
            is_email: boolean
            is_enabled_req: boolean
            is_phone: boolean
            is_town: boolean
            is_zip: boolean
        } | null
        facilita_merchant_id: string
        facilita_api_key: string
        skip_submit_void: boolean
        formats: {
            [key: string]: string
        } | null
        skipForm: boolean
        showReturnButton?: boolean
    } | null
    stateInfo: {
        name: string
    } | null
    bank_info: {
        validate_card: boolean
    } | null
}

const initialState: IInitialState = {
    customer_info: null,
    saved_customer_info: null,
    interface: null,
    main_data: null,
    receipt_data: null,
    request: null,
    state: null,
    ui_config: null,
    stateInfo: null,
    bank_info: null,
    is_4symbol_cvc: false
}

const payReducer = createReducer(initialState, builder => {
    builder
        .addCase(payAction.setPayInfo, (state, action: PayloadAction<IPayInfoData>) => {
            const {
                payload
            } = action
            state.customer_info = payload.customer_info
            state.saved_customer_info = payload.saved_customer_info
            state.interface = payload.interface
            state.main_data = payload.main_data
            state.receipt_data = payload.receipt_data
            state.request = payload.request
            state.state = payload.state
            state.ui_config = payload.ui_config
        })
        .addCase(payAction.setValidationInfo, (state, action: PayloadAction<IValidateCardResponse>) => {
            state.bank_info = action.payload.bank_info
        })
})

export default payReducer
