import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

import { RoutesEnum } from './enums/routes.enum'
import { ModeEnum } from './enums/mode.enum'

const SuccessPage = lazy(() => import('./pages/success-page'))
const ErrorPage = lazy(() => import('./pages/error-page'))
const PayPage = lazy(() => import('./pages/pay-page'))
const TestPage = lazy(() => import('./pages/test-page'))

function App() {
    return (
        <Routes>
            <Route path={RoutesEnum.Pay} element={<PayPage />} />
            <Route path={RoutesEnum.Success} element={<SuccessPage />} />
            <Route path={RoutesEnum.Error} element={<ErrorPage />} />
            {
                process.env.REACT_APP_MODE === ModeEnum.Development && (
                    <Route path={RoutesEnum.TestRoot} element={<TestPage />} />
                )
            }
            <Route path="*" element={<>Route not found</>} />
        </Routes>
    )
}

export default App
